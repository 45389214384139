import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
    const image = useStaticQuery(graphql`
      query {
        comingSoon: file(relativePath: { eq: "coming-soon.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    return (
      <Layout>
        <SEO title="Coming Soon" />
        <section class="hero is-fullheight">
  
          <div class="hero-body" style={{ padding: '0px', alignItems: 'none' }}>
            <div class="container" style={{ margin: `0px` }}>
              <Img style={{
                height: '100vh',
                width: `100vw`,
                position: 'relative'
              }}
                loading="lazy"
                fluid={image.comingSoon.childImageSharp.fluid}
                alt="Search Icon"
              />
            </div>
          </div>
        </section>
      </Layout>
    )
  }